// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-biandbots-js": () => import("/opt/build/repo/src/pages/biandbots.js" /* webpackChunkName: "component---src-pages-biandbots-js" */),
  "component---src-pages-bittest-js": () => import("/opt/build/repo/src/pages/bittest.js" /* webpackChunkName: "component---src-pages-bittest-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investment-js": () => import("/opt/build/repo/src/pages/investment.js" /* webpackChunkName: "component---src-pages-investment-js" */),
  "component---src-pages-machinelearning-js": () => import("/opt/build/repo/src/pages/machinelearning.js" /* webpackChunkName: "component---src-pages-machinelearning-js" */),
  "component---src-pages-marketing-js": () => import("/opt/build/repo/src/pages/marketing.js" /* webpackChunkName: "component---src-pages-marketing-js" */),
  "component---src-pages-page-2-js": () => import("/opt/build/repo/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-research-js": () => import("/opt/build/repo/src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-seminars-js": () => import("/opt/build/repo/src/pages/seminars.js" /* webpackChunkName: "component---src-pages-seminars-js" */),
  "component---src-pages-springboard-js": () => import("/opt/build/repo/src/pages/springboard.js" /* webpackChunkName: "component---src-pages-springboard-js" */),
  "component---src-pages-thankyou-js": () => import("/opt/build/repo/src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-websites-js": () => import("/opt/build/repo/src/pages/websites.js" /* webpackChunkName: "component---src-pages-websites-js" */)
}

